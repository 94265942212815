@use 'sass:color';
@use '@/components/colors';
@use '@/components/icons';

body {
    font-size: 16px !important;
    color: colors.$grey4;
    background-color: colors.$white;
    font-family: 'Open Sans', Verdana, sans-serif;
    font-weight: 400;
}

svg {
    shape-rendering: geometricPrecision;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    color: colors.$black;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-left: 1rem;
    padding-right: 1rem;
}

hr {
    border-top: 1px solid colors.$grey3;
}

button {
    font-size: 1.125rem;
}

.container {
    background: colors.$white;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    border: 1px solid colors.$grey1;

    .row {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 1rem;
    }

    .row:first-of-type {
        margin-top: 0;
    }

    .row:last-of-type {
        margin-bottom: 0;
    }
}

.bodyContent {
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 25rem;

    &.container {
        border: 0;

        &.fullWidth {
            max-width: 95%;

            @media (max-width: 767px) {
                max-width: 100%;
            }

            .container {
                max-width: 100%;
                border: 0;
            }
        }
    }
}

.modal-body .container {
    background: colors.$white;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-bottom: 0;
    border: 1px solid colors.$grey1;
}

.bodyContent h2 {
    background: colors.$white;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 0;
    line-height: 1.5;
}

input[type='text'],
input[type='search'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='password'],
select,
.form-control {
    border: 1px colors.$grey3 solid;
    color: colors.$grey4;
    font-size: 1rem;
    padding: 8px 10px;
    border-radius: 0;
    cursor: pointer;

    &::placeholder {
        color: color.adjust(colors.$activeBlue, $lightness: 10%, $space: hsl);
    }
}

.selectable {
    cursor: pointer;
}

input[type='text']:hover,
input[type='search']:hover,
input[type='email']:hover,
input[type='tel']:hover,
input[type='number']:hover,
input[type='password']:hover,
select:hover,
.form-control:hover {
    border-color: colors.$activeBlue;
}

input[type='text']:focus,
input[type='search']:focus,
input[type='email']:focus,
input[type='tel']:focus,
input[type='number']:focus,
input[type='password']:focus,
.form-control:focus {
    border-color: colors.$brandBlue;
    color: colors.$grey4;
    font-weight: 600;
    outline: none;
    box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: colors.$grey0;
    color: colors.$grey3;
}

.form-control[readonly] {
    font-weight: 600;
}

select,
select.form-control {
    $selectLinkArrow: icons.swap-color(icons.$linkArrowDown, '#FFFFFF', colors.$activeBlue);
    $selectLinkArrowHover: icons.swap-color(icons.$linkArrowDown, '#FFFFFF', colors.$brandBlue);
    $selectLinkArrowActive: icons.$linkArrowUp;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: $selectLinkArrow;
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: right 0.5rem center;
    font-weight: 400;

    &:hover {
        background-image: $selectLinkArrowHover;
    }

    &:active,
    &:focus {
        background-image: $selectLinkArrowActive;
        border-color: colors.$brandBlue;
        background-color: colors.$brandBlue;
        color: colors.$white;
        font-weight: 400;
    }

    optgroup {
        color: colors.$grey4;
        background-color: colors.$grey0;
        font-weight: 600;
        cursor: default;
    }

    option,
    option.option {
        color: colors.$grey4;
        background-color: colors.$white;
        cursor: pointer;

        &.selected {
            background-color: colors.$white;
            color: colors.$brandBlue;
            font-weight: 600;
        }

        &:checked,
        &:hover {
            background-color: colors.$activeBlue;
            color: white;
        }
    }
}

.custom-control {
    .custom-control-input:checked ~ .custom-control-label::before {
        color: colors.$white;
        border-color: colors.$brandBlue;
        background-color: colors.$brandBlue;
    }

    &:hover .custom-control-input:checked ~ .custom-control-label::before,
    &:focus .custom-control-input:checked ~ .custom-control-label::before,
    &:active .custom-control-input:checked ~ .custom-control-label::before {
        color: colors.$white;
        border-color: colors.$activeBlue;
        background-color: colors.$activeBlue;
    }

    .custom-control-input:not(:checked) ~ .custom-control-label::before {
        color: colors.$activeBlue;
        border-color: colors.$activeBlue;
        background-color: colors.$white;
    }

    &:hover .custom-control-input:not(:checked) ~ .custom-control-label:before,
    &:focus .custom-control-input:not(:checked) ~ .custom-control-label:before,
    &:active .custom-control-input:not(:checked) ~ .custom-control-label:before {
        color: colors.$brandBlue;
        border-color: colors.$brandBlue;
        background-color: colors.$white;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        outline: none;
        box-shadow: none;
        background-color: colors.$activeBlue;
        border-color: colors.$activeBlue;
    }

    .custom-control-input:focus ~ .custom-control-label {
        font-weight: 600;
    }
}

label {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
}

.custom-radio label {
    font-weight: 400;
}

.error {
    color: #e31937;
}

a {
    color: colors.$activeBlue;
    cursor: pointer;
    font-size: 1rem;
    height: auto;
    padding: 0;
    text-decoration: none;
}

a:focus,
a:hover,
a:active {
    color: colors.$brandBlue;
    text-decoration: none;
}

.bodyContent {
    a,
    a:hover,
    a:active {
        text-decoration: underline;
    }

    a.btn {
        text-decoration: none;
    }
}

h1 {
    font-size: 1.4rem;
}

h2 {
    font-size: 1.5625rem;
}

h3,
h3 label {
    font-size: 1.375rem;
    font-weight: normal;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.125rem;
    font-weight: 600;
}

@media (min-width: 767px) {
    a[href^='tel:'] {
        text-decoration: none;
    }
}

.container {
    @media (max-width: 767px) {
        width: auto;
        max-width: 100%;
    }
}

.container,
.container-lg,
.container-md,
.container-sm {
    @media (min-width: 850px) {
        max-width: 820px;
    }

    @media (min-width: 900px) {
        max-width: 870px;
    }

    @media (min-width: 930px) {
        max-width: 900px;
    }

    @media (min-width: 990px) {
        max-width: 960px;
    }
}

.line-breaks {
    white-space: pre-line;
}

.formGroup {
    margin-bottom: 1rem;
}

hr.dark {
    border-color: colors.$grey3;
}

b,
th {
    font-weight: 600;
}

input[type='number'] {
    width: 12rem;

    &.noSpinner {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
    }
}
